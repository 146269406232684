"use client";

import React, { useEffect, useState } from "react";
import MediaModal from "./index";

const ModalTrigger = () => {
    const [openMediaModal, setOpenMediaModal] = useState(false);

    useEffect(() => {
        const btnClick = document.querySelector(".jltwp-spotlight-play-btn");
        btnClick?.addEventListener("click", () => {
            if (!openMediaModal) {
                setOpenMediaModal(true);
            }
        });
    }, []);
    return <>{openMediaModal ? <MediaModal setOpenMediaModal={setOpenMediaModal} /> : null}</>;
};

export default ModalTrigger;
