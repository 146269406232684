"use client";

import { buttonVariants } from "@/components/ui/button";
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { cn } from "@/utils";
import { HelpCircleIcon, LucideIcon, ZapIcon } from "lucide-react";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import MaxWidthWrapper from "../global/max-width-wrapper";
import MobileNavbar from "./mobile-navbar";
import AnimationContainer from "../global/animation-container";
// import Image from "next/image";

const NavbarClient = ({ menus }) => {
    const [scroll, setScroll] = useState(false);

    let newMenus = menus.reduce((acc, curr) => {
        if (curr?.is_mega_menu) {
            const megaMenu = {};

            Object.assign(megaMenu, {
                title: curr?.menu_item?.label,
                href: curr?.menu_item?.destination,
                blank: curr?.menu_item?.new_window,
            });

            if (curr?.items?.length) {
                const megaMenuItems = [];
                curr?.items.forEach((item) => {
                    megaMenuItems.push({
                        title: item?.menu_heading,
                        href: item?.menu_url,
                        blank: item?.new_window,
                        tagline: item?.menu_sub_heading,
                        icon: HelpCircleIcon,
                    });
                });

                Object.assign(megaMenu, {
                    ...megaMenu,
                    menu: megaMenuItems,
                });
            }

            acc.push(megaMenu);
        } else if (curr?.items?.length > 0 && !curr?.is_mega_menu) {
            const subMenu = {};
            Object.assign(subMenu, {
                title: curr?.menu_item?.label,
                href: curr?.menu_item?.destination,
                blank: curr?.menu_item?.new_window,
            });
            const subMenuItems = [];
            curr?.items.forEach((item) => {
                subMenuItems.push({
                    title: item?.menu_heading,
                    href: item?.menu_url,
                    blank: item?.new_window,
                    tagline: item?.menu_sub_heading,
                    icon: HelpCircleIcon,
                });
            });
            Object.assign(subMenu, {
                ...subMenu,
                menu: subMenuItems,
            });
            acc.push(subMenu);
        } else {
            acc.push({
                title: curr?.menu_item?.label,
                href: curr?.menu_item?.destination,
                blank: curr?.menu_item?.new_window,
            });
        }

        return acc;
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 10) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header
            className={cn(
                "jlt-sticky jlt-top-0 jlt-inset-x-0 jlt-h-20 jlt-w-full jlt-border-b jlt-border-transparent jlt-z-[99999] jlt-select-none",
                scroll && "jlt-border-background/80 jlt-bg-background/40 jlt-backdrop-blur-md"
            )}>
            <AnimationContainer reverse delay={0.1} className="jlt-size-full">
                <MaxWidthWrapper className="jlt-flex jlt-items-center jlt-justify-between">
                    <div className="jlt-flex jlt-items-center jlt-space-x-12">
                        <Link href="/">
                            <img
                                src="/assets/spotlight.svg"
                                alt="Dashboard"
                                width={172}
                                height={32}
                            />
                        </Link>

                        <NavigationMenu className="jlt-hidden lg:jlt-flex">
                            <NavigationMenuList>
                                {(newMenus || []).map((link) => (
                                    <NavigationMenuItem key={link.title}>
                                        {link.menu ? (
                                            <>
                                                <NavigationMenuTrigger>
                                                    {link.title}
                                                </NavigationMenuTrigger>
                                                <NavigationMenuContent>
                                                    <ul
                                                        className={cn(
                                                            "jlt-grid jlt-gap-1 jlt-p-4 md:jlt-w-[400px] lg:jlt-w-[500px] jlt-rounded-xl lg:jlt-grid-cols-2"
                                                            // link.title === "Features"
                                                            //     ? "lg:jlt-grid-cols-[.75fr_1fr]"
                                                            //     : "lg:jlt-grid-cols-2"
                                                        )}>
                                                        {/* {link.title === "Features" && (
                                                            <li className="jlt-row-span-4 jlt-pr-2 jlt-relative jlt-rounded-lg jlt-overflow-hidden">
                                                                <div className="jlt-absolute jlt-inset-0 !jlt-z-10 jlt-h-full jlt-w-[calc(100%-10px)] jlt-bg-[linear-gradient(to_right,rgb(38,38,38,0.5)_1px,transparent_1px),linear-gradient(to_bottom,rgb(38,38,38,0.5)_1px,transparent_1px)] jlt-bg-[size:1rem_1rem]"></div>
                                                                <NavigationMenuLink
                                                                    asChild
                                                                    className="jlt-z-20 jlt-relative"
                                                                >
                                                                    <Link
                                                                        href={"/features"}
                                                                        className="jlt-flex jlt-h-full jlt-w-full jlt-select-none jlt-flex-col jlt-justify-end jlt-rounded-lg jlt-bg-gradient-to-b jlt-from-muted/50 jlt-to-muted jlt-p-4 jlt-no-underline jlt-outline-none focus:jlt-shadow-md"
                                                                    >
                                                                        <h6 className="jlt-mb-2 jlt-mt-4 jlt-text-lg jlt-font-medium">
                                                                            All Features
                                                                        </h6>
                                                                        <p className="jlt-text-sm jlt-leading-tight jlt-text-muted-foreground">
                                                                            Manage links, track
                                                                            performance, and more.
                                                                        </p>
                                                                    </Link>
                                                                </NavigationMenuLink>
                                                            </li>
                                                        )} */}
                                                        {link.menu.map((menuItem) => (
                                                            <ListItem
                                                                key={menuItem.title}
                                                                title={menuItem.title}
                                                                href={menuItem.href}
                                                                icon={menuItem.icon}
                                                                {...(link.blank
                                                                    ? { target: "_blank" }
                                                                    : {})}>
                                                                {menuItem.tagline}
                                                            </ListItem>
                                                        ))}
                                                    </ul>
                                                </NavigationMenuContent>
                                            </>
                                        ) : (
                                            <Link
                                                href={link.href}
                                                target="_b"
                                                legacyBehavior
                                                passHref>
                                                <NavigationMenuLink
                                                    {...(link.blank ? { target: "_blank" } : {})}
                                                    className={navigationMenuTriggerStyle()}>
                                                    {link.title}
                                                </NavigationMenuLink>
                                            </Link>
                                        )}
                                    </NavigationMenuItem>
                                ))}
                            </NavigationMenuList>
                        </NavigationMenu>
                    </div>
                    <div className="jlt-items-center jlt-gap-x-4 jlt-hidden lg:jlt-flex">
                        <Link href="/pricing" className={buttonVariants({ size: "sm" })}>
                            Pricing
                            <ZapIcon className="jlt-size-3.5 jlt-ml-1.5 jlt-text-orange-500 jlt-fill-orange-500" />
                        </Link>
                    </div>
                    <MobileNavbar newMenus={newMenus} />
                </MaxWidthWrapper>
            </AnimationContainer>
        </header>
    );
};

const ListItem = ({ className, title, href, icon: Icon, children, ...props }) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <Link
                    href={href}
                    // ref={ref}
                    className={cn(
                        "jlt-block jlt-select-none jlt-space-y-1 jlt-rounded-lg jlt-p-3 jlt-leading-none jlt-no-underline jlt-outline-none jlt-transition-all jlt-duration-100 jlt-ease-out hover:jlt-bg-accent hover:jlt-text-accent-foreground focus:jlt-bg-accent focus:jlt-text-accent-foreground",
                        className
                    )}
                    {...props}>
                    <div className="jlt-flex jlt-items-center jlt-space-x-2 jlt-text-neutral-300">
                        <Icon className="jlt-h-4 jlt-w-4" />
                        <h6 className="jlt-text-sm jlt-font-medium !jlt-leading-none">{title}</h6>
                    </div>
                    <p
                        title={children}
                        className="jlt-line-clamp-1 jlt-text-sm jlt-leading-snug jlt-text-muted-foreground">
                        {children}
                    </p>
                </Link>
            </NavigationMenuLink>
        </li>
    );
};
// ListItem.displayName = "ListItem";/*  */

export default NavbarClient;
