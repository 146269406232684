"use client";

import * as React from "react";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

import { cn } from "@/utils";

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Overlay
        className={cn(
            "jlt-fixed jlt-inset-0 jlt-z-50 jlt-bg-black/80 data-[state=open]:jlt-animate-in data-[state=closed]:jlt-animate-out data-[state=closed]:jlt-fade-out-0 data-[state=open]:jlt-fade-in-0",
            className
        )}
        {...props}
        ref={ref}
    />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
    "jlt-fixed jlt-z-50 jlt-gap-4 jlt-bg-background jlt-p-6 jlt-shadow-lg jlt-transition jlt-ease-in-out data-[state=open]:jlt-animate-in data-[state=closed]:jlt-animate-out data-[state=closed]:jlt-duration-300 data-[state=open]:jlt-duration-500",
    {
        variants: {
            side: {
                top: "jlt-inset-x-0 jlt-top-0 jlt-border-b data-[state=closed]:jlt-slide-out-to-top data-[state=open]:jlt-slide-in-from-top",
                bottom: "jlt-inset-x-0 jlt-bottom-0 jlt-border-t data-[state=closed]:jlt-slide-out-to-bottom data-[state=open]:jlt-slide-in-from-bottom",
                left: "jlt-inset-y-0 jlt-left-0 jlt-h-full jlt-border-r data-[state=closed]:jlt-slide-out-to-left data-[state=open]:jlt-slide-in-from-left",
                right: "jlt-inset-y-0 jlt-right-0 jlt-h-full  jlt-border-l jlt-border-border data-[state=closed]:jlt-slide-out-to-right data-[state=open]:jlt-slide-in-from-right",
            },
        },
        defaultVariants: {
            side: "right",
        },
    }
);

interface SheetContentProps
    extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
        VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Content>,
    SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
    <SheetPortal>
        {/* <SheetOverlay /> */}
        <SheetPrimitive.Content
            ref={ref}
            className={cn(sheetVariants({ side }), className)}
            {...props}
        >
            {children}
            <SheetPrimitive.Close className="jlt-absolute jlt-right-6 jlt-top-6 jlt-rounded-sm jlt-ring-offset-background jlt-transition-opacity hover:jlt-opacity-100 focus:jlt-outline-none focus:jlt-ring-2 focus:jlt-ring-ring focus:jlt-ring-offset-2 disabled:jlt-pointer-events-none data-[state=open]:jlt-bg-secondary jlt-opacity-0">
                <X className="h-4 w-4" />
                {/* <span className="sr-only">Close</span> */}
            </SheetPrimitive.Close>
        </SheetPrimitive.Content>
    </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "jlt-flex jlt-flex-col jlt-space-y-2 jlt-text-center jlt-sm:text-left",
            className
        )}
        {...props}
    />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "jlt-flex jlt-flex-col-reverse sm:jlt-flex-row sm:jlt-justify-end sm:jlt-space-x-2",
            className
        )}
        {...props}
    />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Title
        ref={ref}
        className={cn("jlt-text-lg jlt-font-semibold jlt-text-foreground", className)}
        {...props}
    />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
    React.ElementRef<typeof SheetPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Description
        ref={ref}
        className={cn("jlt-text-sm jlt-text-muted-foreground", className)}
        {...props}
    />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
    Sheet,
    SheetPortal,
    SheetOverlay,
    SheetTrigger,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetFooter,
    SheetTitle,
    SheetDescription,
};
