import { X } from "lucide-react";

const MediaModal = ({ setOpenMediaModal }) => {
    return (
        <div className="jltwp-spotlight-media-modal jlt-fixed jlt-top-0 jlt-left-0 jlt-w-[100%] jlt-h-screen jlt-flex jlt-items-center jlt-justify-center">
            <div className="jltwp-spotlight-modal-wrapper">
                <iframe
                    width="960"
                    height="540"
                    src="https://www.youtube.com/embed/XZ0sBGkbvY4?si=aI93JCBzUxqmOEN9?autoplay=1&mute=1"
                    title="WP Spotlight Promo Video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                />
                <button type="button" onClick={() => setOpenMediaModal(false)}>
                    <X />
                </button>
            </div>
        </div>
    );
};

export default MediaModal;
