"use client";

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Button, buttonVariants } from "@/components/ui/button";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn, NAV_LINKS } from "@/utils";
import { LucideIcon, Menu, X } from "lucide-react";
import Link from "next/link";
import React, { useState } from "react";

const MobileNavbar = ({ newMenus }: { newMenus: any }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className="jlt-flex lg:jlt-hidden jlt-items-center jlt-justify-end">
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger asChild>
                    <Button size="icon" variant="ghost">
                        <Menu className="jlt-w-5 jlt-h-5" />
                    </Button>
                </SheetTrigger>
                <SheetContent className="jlt-w-screen">
                    <SheetClose
                        asChild
                        className="jlt-absolute jlt-top-3 jlt-right-5 jlt-bg-background jlt-z-20 jlt-flex jlt-items-center jlt-justify-center jlt-opacity-0">
                        <Button size="icon" variant="ghost" className="jlt-text-neutral-600">
                            <X className="jlt-w-5 jlt-h-5" />
                        </Button>
                    </SheetClose>
                    <div className="jlt-flex jlt-flex-col jlt-items-start jlt-w-full jlt-py-2 jlt-mt-10">
                        <ul className="jlt-flex jlt-flex-col jlt-items-start jlt-w-full jlt-mt-6">
                            <Accordion type="single" collapsible className="!jlt-w-full">
                                {(newMenus || []).map((link: any) => (
                                    <AccordionItem
                                        key={link.title}
                                        value={link.title}
                                        className="last:jlt-border-none">
                                        {link.menu ? (
                                            <>
                                                <AccordionTrigger>{link.title}</AccordionTrigger>
                                                <AccordionContent>
                                                    <ul
                                                        onClick={handleClose}
                                                        className={cn("jlt-w-full")}>
                                                        {link.menu.map((menuItem: any) => (
                                                            <ListItem
                                                                key={menuItem.title}
                                                                title={menuItem.title}
                                                                href={menuItem.href}
                                                                icon={menuItem.icon}>
                                                                {menuItem.tagline}
                                                            </ListItem>
                                                        ))}
                                                    </ul>
                                                </AccordionContent>
                                            </>
                                        ) : (
                                            <Link
                                                href={link.href}
                                                onClick={handleClose}
                                                className="jlt-flex jlt-items-center jlt-w-full jlt-py-4 jlt-font-medium jlt-text-muted-foreground hover:jlt-text-foreground">
                                                <span>{link.title}</span>
                                            </Link>
                                        )}
                                    </AccordionItem>
                                ))}
                                <AccordionItem value="" className="last:jlt-border-none">
                                    <Link
                                        href="/pricing"
                                        onClick={handleClose}
                                        className="jlt-flex jlt-items-center jlt-w-full jlt-py-4 jlt-font-medium jlt-text-muted-foreground hover:jlt-text-foreground">
                                        <span>Pricing</span>
                                    </Link>
                                </AccordionItem>
                            </Accordion>
                        </ul>
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    );
};

const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a"> & { title: string; icon: LucideIcon }
>(({ className, title, href, icon: Icon, children, ...props }, ref) => {
    return (
        <li>
            <Link
                href={href!}
                ref={ref}
                className={cn(
                    "jlt-block jlt-select-none jlt-space-y-1 jlt-rounded-lg jlt-p-3 jlt-leading-none jlt-no-underline jlt-outline-none jlt-transition-colors hover:jlt-bg-accent hover:jlt-text-accent-foreground focus:jlt-bg-accent focus:jlt-text-accent-foreground",
                    className
                )}
                {...props}>
                <div className="jlt-flex jlt-items-center jlt-space-x-2 jlt-text-foreground">
                    <Icon className="jlt-h-4 jlt-w-4" />
                    <h6 className="jlt-text-sm !jlt-leading-none">{title}</h6>
                </div>
                <p
                    title={children! as string}
                    className="jlt-line-clamp-1 jlt-text-sm jlt-leading-snug jlt-text-muted-foreground">
                    {children}
                </p>
            </Link>
        </li>
    );
});
ListItem.displayName = "ListItem";

export default MobileNavbar;
